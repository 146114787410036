* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #272727;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

#root {
  overflow: auto;
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  color: black;
  -webkit-font-smoothing: antialiased;
}

.main {
  position: relative;
  width: 100%;
  height: 100%;
  color: #fff8de;
  overflow: hidden;
}

span.header {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 700;
  position: absolute;
  display: inline-block;
  width: 500px;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
  font-size: 9em;
  line-height: 0.9em;
  pointer-events: none;
  top: 350px;
  left: 50px;
}

span.header-left {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 700;
  position: absolute;
  display: inline-block;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
  line-height: 1em;
  top: 200px;
  left: 60px;
  font-size: 4em;
  width: 200px;
}

div.header-major {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 700;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50px;
}

div.header-major > span {
  font-size: 30em;
}

@media only screen and (max-width: 1000px) {
  div.header-major > span {
    font-size: 20em;
  }
}

@media only screen and (max-width: 800px) {
  div.header-major > span {
    font-size: 15em;
  }
  span.header {
    top: 200px;
    left: 60px;
    font-size: 4em;
    width: 200px;
  }
  a.bottom-left {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  div.header-major > span {
    font-size: 10em;
  }
}

a {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: inherit;
  position: absolute;
  display: inline;
  text-decoration: none;
  z-index: 1;
}

a.top-left1 {
  top: 60px;
  left: 60px;
}

a.top-left2 {
  top: 60px;
  left: 180px;
}

a.top-right1 {
  top: 60px;
  right: 300px;
}

a.top-right2 {
  top: 60px;
  right: 180px;
}

a.top-right3 {
  top: 60px;
  right: 60px;
}

a.bottom-left1 {
  bottom: 60px;
  left: 60px;
}

a.bottom-left2 {
  bottom: 60px;
  left: 240px;
}

a.bottom-right {
  bottom: 60px;
  right: 60px;
}

canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.grid {
  display: flex;
  flex-wrap: wrap;
}

.grid .item {
  position: relative;
  width: 50vw;
  height: 50vw;
  background: #eee;
}

@media only screen and (max-width: 480px) {
  .grid .item {
    width: 100vw;
    height: 100vw;
  }
}

.scroll-container {
  position: absolute;
  overflow: auto;
  top: 0px;
  width: 100%;
  height: 100vh;
  font-size: 20em;
  font-weight: 800;
  line-height: 0.9em;
}

